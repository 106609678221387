var globalMixin = {
    methods: {
        onReset({router, clear}) {
            router.push({name: 'home'})
            clear()
        },
        toTitleCase(str) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
    }
}

export default globalMixin
