<template>
  <div class="config">
    <BaseMessage
        v-if="hasErrors"
        v-model="hasErrors"
        :title="translate('error', 'Error')"
        :description="errMsg"
        :confirmLabel="translate('okay', 'Okay')"
        :hasCancel="false"
        :is-idle="false"
        @on-reset="onOkay"
    >
    </BaseMessage>
    <section class="config__container d-flex flex-column justify-center align-center pa-5" ref="form">
      <img style="max-width: 480px; width: 100%" src="@/assets/img/config/solo.kiosk.svg" alt="">
      <v-text-field
          class="login-input"
          ref="username"
          v-model="username"
          outlined
          label="Username"
          :rules="[rules.required]"
          @focus="onFocus('username')"
          @blur="onBlur()"
          required
      ></v-text-field>
      <v-text-field
          class="login-input"
          type="password"
          ref="password"
          v-model="password"
          outlined
          label="Password"
          :rules="[rules.required]"
          @focus="onFocus('password')"
          @blur="onBlur()"
          required
      ></v-text-field>
      <v-btn
        elevation="2"
        class="login-btn mb-8"
        depressed
        style="background: #fff"
        @click="onLogin"
        x-large
      >
        <v-progress-circular
          v-if="isLoading"
          class="mr-3"
          :size="20"
          color="primary"
          indeterminate
        ></v-progress-circular> Login
      </v-btn>
      <div v-show="input" class="simple-keyboard"></div>
    </section>
  </div>

</template>
<style lang="scss">
.config, .config__container {
  height: 100%;
  .v-input {
    display: block;
    flex: none;
  }
  h1 {
    font-size: 32px;
  }
  .login-input, .login-btn {
    max-width: 480px;
    width: 100%;
  }
}
</style>
<script>
import Keyboard from 'simple-keyboard';
import { mapMutations } from 'vuex';
import { Auth } from '../../services/SOLO';
import translationMixin from '../../mixins/translations';
import BaseMessage from '../../components/base/BaseMessage';
import 'simple-keyboard/build/css/index.css';

export default {
  name: 'ConfigLogin',
  mixins: [translationMixin],
  components: {
    BaseMessage,
  },
  computed: {
    form() {
      return {
        username: this.username,
        password: this.password,
      };
    },
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'This field is required.',
      },
      username: '',
      password: '',
      formHasErrors: false,
      isLoading: false,
      hasErrors: false,
      errMsg: '',
      keyboard: null,
      input: null,
      blockBlur: false,
    };
  },
  mounted() {
    this.keyboard = new Keyboard('simple-keyboard', {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
    });
  },
  methods: {
    ...mapMutations({
      setUser: 'config/setUser',
      setIsLoggedIn: 'config/setIsLoggedIn',
      setSelectedConcept: 'config/setSelectedConcept',
    }),
    onLogin() {
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f]?.validate(true);
      });
      if (!this.formHasErrors) {
        if (!this.isLoading) {
          this.isLoading = true;
          Auth.login(this.form)
            .then((response) => {
              this.setUser(response.data.data);
              this.setSelectedConcept(response.data.data.attributes.concepts[0]);
              this.setIsLoggedIn(true);
              this.$router.push({ name: 'config.form' });
            })
            .catch((err) => {
              console.log(err);
              this.hasErrors = true;
              err?.response?.data?.error?.forEach((e) => {
                this.errMsg += `<p style="text-transform: capitalize">${e?.detail}</p>`
              });
              if (!this.errMsg) {
                if (err?.response?.data?.error) {
                  this.errMsg = err?.response?.data?.error.detail;
                } else {
                  this.errMsg = 'Something went wrong!';
                }
              }
            })
            .then(() => (this.isLoading = false));
        }
      }
    },
    onOkay() {
      this.errMsg = '';
      this.hasErrors = false;
    },
    onChange(input) {
      this[this.input] = input;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[this.input].focus();
        }, 200);
      });
    },
    onKeyPress(button) {
      this.blockBlur = true;
      if (button === '{shift}' || button === '{lock}'){
        this.handleShift();
      }
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    onFocus(inputType) {
      this.input = inputType;
      this.keyboard.setInput(this[this.input]);
    },
    onBlur() {
      console.log('this.blockBlur: ', this.blockBlur);
      if (this.blockBlur) {
        this.blockBlur = false;
        return;
      }
      this.input = null;
    },
  }
}
</script>
