<template>
    <v-row justify="center">
        <v-dialog v-model="model" eager persistent width="822">
            <v-card class="page">
                <v-container class="px-6">
                    <h1 class="text-center page-title my-5 ">{{ title }}</h1>
                    <p class="text-center page-description mb-8" v-if="description.includes('</')" v-html="description"></p>
                    <p class="text-center page-description mb-8" v-else>
                      {{ description || translate('idle_subtitle', 'We’ve noticed you haven’t touched the screen for a while now. Would you like to restart this order?') }}
                    </p>
                    <section class="d-flex justify-center">
                        <solo-button class="ma-5" @click="$emit('on-reset', {router: $router, clear: clear})" width="300">{{ confirmLabel || toTitleCase(translate('restart', 'Restart')) }}</solo-button>
                        <solo-button class="ma-5" @click="model = false" width="300" outlined v-if="hasCancel">{{ toTitleCase(translate('cancel','CANCEL')) }}</solo-button>
                    </section>
                    <section class="d-flex justify-center idle-seconds" v-if="isIdle">
                      {{ translate('automatic_restart', 'Will automatically restart in') }}&nbsp;<span>{{ this.seconds }}{{ translate('letter_s', 's') }}</span>
                    </section>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapMutations } from 'vuex';
import translationMixin from '@/mixins/translations';
import globalMixin from '../../mixins/global';
import SoloButton from '@/components/base/SoloButton.vue';

export default {
    name:'BaseMessage',
    components:{
        SoloButton
    },
    mixins: [translationMixin, globalMixin],
    props:{
        confirmLabel:{
            type: String,
            default: ''
        },
        title:{
            type: String,
            default: null
        },
        value:{
            type: Boolean,
            default: false
        },
        description: {
          type: String,
          default: '',
        },
        isIdle: {
          type: Boolean,
          default: true,
        },
      hasCancel: {
          type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        seconds: 30,
        default: 30,
        idleText: false,
        interval: null
      }
    },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        console.log('value = handler: ', v)
        if(!v) {
          this.seconds = this.default
          clearInterval(this.interval)
        } else {
          if (this.isIdle) {
            this.resetInterval()
            this.interval = setInterval(() => {
              if(this.seconds) {
                this.seconds--
                this.resetInterval()
              } else {
                this.seconds = this.default
                clearInterval(this.interval)
                this.$emit('on-reset', {router: this.$router, clear: this.clear})
                this.model = false
              }
            }, 1000)
          } else {
            this.idleText = false;
          }
        }
      }
    }
  },
  computed:{
    model:{
      get(){
        return this.value
      },
      set(v){
        this.$emit('input', v)
      }
    }
  },
  methods: {
    ...mapMutations({
      clear: 'cart/clear'
    }),
    resetInterval() {
      this.idleText = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.idle-seconds {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  text-align: center;

  color: #1E1E1E;
  margin-bottom: 10px;
  span {
    color: var(--v-primary-base);
  }
}
    .page{
        color: #1E1E1E !important;
        &-title{
            color: var(--v-primary-base);
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 48px !important;
            line-height: 96px !important;
        }
        &-description{
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 42px;
            text-align: center;
        }
        .btn{
            border-radius: 20px !important;
            width: 300px !important;
            height: 123px !important;
            font-style: normal !important;
            font-weight: bold;
            font-size: 36px;
            text-align: center !important;
            &-restart{
                text-transform: capitalize !important;
                background: var(--v-primary-base) !important;
                color: #FFFFFF;
            }
            &-cancel{
                text-transform: capitalize !important;
                border: 6px solid !important;
            }
        }
    }
</style>
